/*------------------------------------------------------------------
  [Bootstrap grid]
*/

.row{

	@include phone {
		margin-left: -10px;
		margin-right: -10px;
	}
}
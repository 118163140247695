.code-editor {
  height: 100%;
}

.aside-content .nav {
  display: block;
}

.aside-content .nav li a {
  display: block;
  position: relative;
} 

.aside-content .nav li a:hover{
  text-decoration: none;
  background-color: $body-bg;
}

.code-editor #console {  
  height: 100%;
}

.code-editor #console .CodeMirror { 
  height: 100%;
}

.codeditor .page-title {
  font-size: 2rem;
  margin-bottom: 0;
  margin-top: 10px;
}

.codeditor .description {
  color: #888;
}

.page-aside.codeditor .compose {
  margin-top: 10px;
  font-size: 1.077rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 25px;
}

.page-aside.codeditor + .main-content {
  height: calc(100vh - #{($top-header-height + 1)});
  width: auto;

  @include tablet {
    height: auto;
  }
}
.page-aside.codeditor .compose .btn {
  width: 100%;
  border: 0;
  border-radius: 3px !important;
  max-width: 180px;
}

.page-aside.codeditor .mail-nav {
  display: block;
  margin-top: 13px;
  padding: 0;
}

.page-aside.codeditor .mail-nav ul li {
  margin: 0;
}

.page-aside.codeditor .mail-nav ul li a {
  border-radius: 0;
  color: #555; 
  padding: 12px 15px;
}

.page-aside.codeditor .mail-nav ul li a:hover {
  background: rgba(0,0,0,0.01);
}

.page-aside.codeditor .mail-nav ul li.active a {
  color: #555;
  background: rgba(0,0,0,0.025);
}

.page-aside.codeditor .mail-nav ul li a .label {
  border-radius: 2px;
}

.page-aside.codeditor .mail-nav ul li a .label.label-default {
  background: #A1A1A1 + 20%;
}

.page-aside.codeditor .mail-nav ul li a .label.label1 {
  background: #19B698;
}

.page-aside.codeditor .mail-nav ul li a .label.label2 {
  background: #E85647;
}

.page-aside.codeditor .mail-nav ul li a .label.label3 {
  background: #F4A425;
}

.page-aside.codeditor ul li a span.unsaved {
  position: absolute;
  right: 22px;
  top: 50%;
  margin-top: -5px;
  content:'';
  background-color: theme-color("primary");
  border-radius: 100%;
  display: block;
  height: 10px;
  width: 10px;
  line-height: 10px;
}

.page-aside.codeditor .mail-nav ul li a i {
  font-size: 1.077rem;
  width: 24px;
  text-align: center;
}

.page-aside.codeditor .mail-nav .title {
  color: #888;
  margin: 5px 0 0 0;
  padding: 8px 13px 4px;
  border-bottom: 1px solid #E5E5E5;
}

/*inbox*/
.mail-inbox .head {
  background: #FFF;
  padding: 20px 25px;
}

.mail-inbox .head h3 {
  margin: 9px 0 15px 0;
}

.mail-inbox .head h3 span {
  color: #888;
  font-weight: 300;
  font-size: 1.231rem;
}

.mail-inbox .head input[type="text"] {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) inset;
  border: 1px solid #D8D8D8;
  border-radius: 3px;
}

.mail-inbox .head input[type="text"]:focus {
  border-color: #2598F9 + 30%;
}

.mail-inbox .filters {
  padding: 9px 20px 10px 20px;
  border-bottom: 1px solid #E5E5E5;
}

.mail-inbox .filters .checkbox {
  margin-right: 8px;
}

.mail-inbox .mails {
  background: #FFF;
}

.mail-inbox .mails .item {
  border-bottom: 1px solid #E5E5E5;
  padding: 17px 20px;
  display: table;
  width: 100%;
  cursor: pointer;
}

.mail-inbox .mails .item:nth-child(even) {
  background: rgba(0,0,0,0.015);
}

.mail-inbox .mails .item:hover {
  background: rgba(0,0,0,0.035);
}

.mail-inbox .mails .item > div {
  vertical-align: top;
  display: table-cell;
}

.mail-inbox .mails .item > div:first-child {width:35px;}
.mail-inbox .mails .item > div:first-child > div {margin-top:2px;}
.mail-inbox .checkbox {background-color:#FFF;}

.mail-inbox .mails .item .from {
  font-weight: 600;
  font-size: 1rem;
  margin:0 0 3px 0;
}

.mail-inbox .mails .item .date i {margin-right:7px;font-size:1.077rem;}
.mail-inbox .mails .item .msg {
  font-size: .9231rem;
  margin:0;
}

/*Message*/
.message .head {
  background: #FFF;
  padding: 20px 25px 24px;
}

.message .head h3 {
  margin: 9px 0 15px 0;
}

.message .head h3 span {
  font-weight: 300;
  font-size: 1.538rem;
  float: right;
}

.message .head h3 span a {
  color: #888;
  margin-right: 15px;
}

.message .head h4 span {
  font-weight: 100;
  font-size: 1.154rem;
  float: right;
}

.message .head h4 span a {
  color: #FEA200;
  margin-right: 10px;
}

.message .mail {
  background: #FFF;
  border-top: 1px solid #E5E5E5;
  padding: 30px;
  font-size: 2rem;
}

.message .mail p {
  font-size: 1.154rem;
  font-weight: 100;
}

.message .mails .item {
  border-bottom: 1px solid #E5E5E5;
  padding: 17px 20px;
  display: table;
  width: 100%;
  cursor: pointer;
}

.message .mails .item:nth-child(even) {
  background: rgba(0,0,0,0.015);
}

.message .mails .item:hover {
  background: rgba(0,0,0,0.035);
}

.message .mails .item > div {
  vertical-align: top;
  display: table-cell;
}

.message .mails .item > div:first-child {
  width: 35px;
}

.message .mails .item > div:first-child > div {
  margin-top: 2px;
}

.message .checkbox {
  background-color: #FFF;
}

.message .mails .item .from {
  font-weight: 600;
  font-size: 1rem;
  margin: 0 0 3px 0;
}

.message .mails .item .date i {margin-right:7px;font-size:1.077rem;}
.message .mails .item .msg {
  font-size: .9231rem;
  margin: 0;
}

@media (max-width: 767px) {
  .cl-mcont.aside{
    display: block;
  }
  
  .page-aside.codeditor{
    display: block;
  }

  .aside .content{
    display: block;
  }
  
  .page-aside > div {
    width: 100%;
  }
  
  .cl-mcont.aside > .content {
    display: block;
  }
  
  .page-aside.codeditor .mail-nav ul li {
    display: block;
  }
  
  .page-aside.codeditor .mail-nav{
    margin-top: 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
  }
  
  .page-aside.codeditor .mail-nav .compose{
    border-top: 1px solid #E5E5E5;
    background: #FCFCFC;
  }
  
  .page-aside.codeditor div.content{
    border-bottom: 1px solid #E5E5E5;
  }
  
  .page-aside.codeditor .mail-nav.collapse.show{display:block;}
  .page-aside.codeditor .mail-nav.collapse{display:none;}
  .message .mail{border-top: 0;}
}

.CodeMirror {
  height: auto;
}

.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto;

}
